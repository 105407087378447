.guest-initial-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content {
    width: 224px;
    .message {
      margin-top: 20px;
      font-size: 14px;
      letter-spacing: 0;
      text-align: center;
      color: #51575c;
    }
  }
}

@media only screen and (min-height: 450px) {
  .guest-initial-screen {
    .content {
      transform: translateY(-50px);
    }
  }
}
