.account-tab-content {
  &__guest-box {
    width: 100%;
    margin-top: 33px;
    text-align: center;
    color: #182026;
    overflow: hidden;
    overflow-y: auto;

    @media screen and (max-height: 500px) {
      margin-top: 23px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    color: #000;
  }

  &__plans {
    font-size: 18px;
    margin-top: 103px;

    @media screen and (max-height: 500px) {
      margin-top: 43px;
    }

    a {
      color: #0080ff;
      text-decoration: underline;
    }
  }

  &__user-box {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  }

  &__img {
    width: 140px;
    height: 140px;
    background-color: #a5a5a5;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    color: white;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
      object-fit: contain;
    }
  }

  &__email {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &::before {
      content: "";
      display: block;
      width: 24px;
      height: 16px;
      background-image: url("../../../../assets/images/settings/mail.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 7px;
    }

    a {
      color: #717171;
      opacity: 1;
      font-size: 14px;
      text-decoration: none;
      word-break: break-all;
      padding: 4px 6px;
      border-radius: 2px;
      display: block;
      transition: background-color 100ms;

      &:hover {
        text-decoration: none;
        color: #717171;
        background-color: #efeeee;
      }
    }
  }

  &__actions {
    margin-top: auto;

    ul {
      list-style-type: none;
      color: #182026;
      margin: 0;
      padding: 0;

      li {
        font-size: 14px;
        padding: 5px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }

  &__change-pass,
  &__logout {
    &::before {
      content: "";
      display: block;
      width: 13px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 7px;
      background-position: center;
    }
  }

  &__change-pass {
    &::before {
      background-image: url("../../../../assets/images/settings/lock_grey.png");
      height: 17px;
    }
  }

  &__logout {
    &::before {
      background-image: url("../../../../assets/images/settings/sign-out-option.png");
      height: 12px;
    }
  }

  &__login {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
      max-width: 150px;
    }
  }

  .bp4-intent-orange {
    min-width: 224px;
    width: auto;
    height: 40px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 35px;
    border-radius: 3px;
    text-transform: uppercase;
    background-color: #e6aa5b;
    color: white;
    margin-top: 15px;

    &:hover,
    &:focus {
      color: #fff;
    }

    &[disabled] {
      background-color: #9b9b9b;
      color: #ddd;
    }
  }
  .bp4-button.bp4-intent-success {
    background-color: #83c36d;
    border-radius: 3px;
  }
}
