.stethoscope-panel {
  right: 6px;
  bottom: 52px;
  width: fit-content;
  height: fit-content;

  .vc-floating-panel {
    &__body {
      min-width: 140px;
    }
  }
}
