.clearfix:after {
  content: " "; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.popup-bg {
  z-index: 10003;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    // Width of vertical scroll bar
    -webkit-appearance: none;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #73879a !important;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:horizontal:hover,
  ::-webkit-scrollbar-thumb:vertical:hover {
    background: #73879a;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:horizontal:active,
  ::-webkit-scrollbar-thumb:vertical:active {
    background: #73879a;
  }
}

.device-panel {
  position: relative;
  overflow: hidden;
  width: 448px;
  height: 469px;
  background: #fff;
  border-radius: 10px;
  padding: 24px 48px 0px 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.4);
}

.mobile-cointainer.device-panel {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  display: inline-grid;
}

.device-panel .select-list-header {
  font-size: 12px;
  color: #51575c;
  font-weight: 600;
}

.device-panel .device-check-cointainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-panel .bp4-select-list .bp4-popover-target .bp4-button {
  font-size: 12px;
  color: #51575c;
  padding-top: 0;
  border: none;
  padding-right: 0px;

  svg {
    width: 12px;
    height: 12px;
  }
}
.device-panel .camera-que-cointainer {
  text-align: center;
  margin: 18px 0 8px;
  font-size: 12px;
  color: #51575c;
  font-weight: 400;
}

.device-panel .que-cointainer {
  text-align: center;
  font-size: 12px;
  color: #51575c;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 8px;
  }
}
.device-panel .drop-down-list {
  margin: 20px 0 0 0;
  padding: 0px 0 5px 0;
  border-bottom: 1.4px solid #b8bcc0;
  width: 224px;
  position: relative;
  height: 40px;
}
.device-panel .self-view {
  height: 124px;
  width: 224px;
  border-radius: 10px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}
.device-panel .drop-down-list i {
  display: inline-block;
  position: absolute;
  top: 8px;
}
.device-panel .drop-down-list i img {
  display: inline-block;
  width: 26px;
  cursor: pointer;
}
.device-panel .drop-down-list label {
  color: #51575c;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 4px;
  left: 30px;
}
.device-panel .drop-down-list .cus-select {
  margin: 22px 0 0 32px;
  color: #51575c;
  font-size: 12px;
}
.arrow-drop-down {
  position: absolute;
  top: 45%;
  right: 5px;
  width: 3px;
  height: 3px;
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #51575c;
  border-bottom: 2px solid #51575c;
  transition: 0.2s ease;
  cursor: pointer;
}

.device-panel .heading-txt {
  color: #51575c;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  span {
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #51575c;
  }
}
.mobile-cointainer.device-panel .heading-txt {
  font-size: 16px;
  span {
    text-decoration: underline;
  }
}
.device-panel .test-speaker {
  margin: 23px 0 0 0;
  padding: 0;
  text-align: center;
}
.device-panel .test-speaker img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.device-panel .test-speaker .lbl {
  color: #107db7;
  font-size: 12px;
}
.device-panel .output {
  margin: 8px 0 64px 0;
  padding: 0;
  text-align: center;
  width: 65%;
}
.device-panel .output .lbl {
  color: #51575c;
  font-size: 10px;
}

.device-panel .output .output-circle {
  margin: 8px 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.device-panel .output .output-circle span {
  margin: 0 3px;
  padding: 0;
  background: #e8ebed;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.browser.device-panel .heading-txt br {
  display: none;
}

.device-panel .audio-radio {
  padding: 0;
}
.device-panel .audio-radio .lbl {
  margin: 0;
  padding: 0;
  color: #51575c;
  font-size: 12px;
}
.device-not-working-warn {
  font-size: 10px;
  color: #e4281b;
  visibility: hidden;
}

.device-not-working-warn.show {
  font-size: 10px;
  color: #e4281b;
  visibility: visible;
}

.mobile-cointainer .device-not-working-warn {
  font-size: 14px;
  text-align: center;
}
.device-panel .slide-circle {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 24px;
}
.device-panel .slide-circle span {
  margin: 0 5px;
  padding: 0;
  background: #e8ebed;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
.device-panel .slide-circle span.active {
  background: #107db7;
}
.device-panel .btn[disabled] {
  background: #6d767e;
}
.device-panel .btn {
  background: #107db7;
  width: 224px;
  height: 36px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  margin-top: 30px;
  font-weight: 500;
}

.device-panel .btn.reduce-margin {
  margin-top: 17px;
}

#sound-test-btn {
  display: inline-flex;
  background-color: transparent;
  outline: none;
  border: none;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #107db7;
}

#speaker-test-btn {
  display: inline-block;
  width: 12px;
  height: 13px;
  margin-right: 5px;
  background-image: url(../../assets/images/hardware_check/icon_play_blue.svg);
  background-repeat: no-repeat;
}

.mobile-cointainer #sound-test-btn {
  font-size: 16px;
  font-weight: 400;
}

#sound-test-btn[disabled] {
  color: #51575c;
}
#sound-test-btn.play[disabled],
#sound-test-btn.start-recording-sound[disabled] {
  color: #a9b2b9;
}

#sound-test-btn.play::before {
  content: url(../../assets/images/hardware_check/icon_play_blue.svg);
}
#sound-test-btn.play[disabled]::before {
  content: url(../../assets/images/hardware_check/icon_play_disable.svg);
}
#sound-test-btn.playing::before {
  content: url(../../assets/images/hardware_check/icon_stop_blue.svg);
}
#sound-test-btn[disabled]::before {
  content: url(../../assets/images/hardware_check/icon_play_disabled.svg);
}

#sound-test-btn.start-recording-sound::before {
  content: url(../../assets/images/hardware_check/icon_record.svg);
}

#sound-test-btn.start-recording-sound[disabled]::before {
  content: url(../../assets/images/hardware_check/icon_record_disable.svg);
}

#sound-test-btn.stop-recording-sound::before {
  content: url(../../assets/images/hardware_check/icon_stop_blue.svg);
}

.bp4-portal,
.neo-in-frame .bp4-transition-container {
  z-index: 10003 !important;
}

.radio-no {
  margin-right: 0px !important;
}

.output-label {
  font-size: 10px;
  color: #51575c;
}

.mobile-cointainer .output-label {
  font-size: 14px;
  font-weight: 400;
}

.mobile-cointainer.device-panel .que-cointainer {
  font-size: 16px;
  margin-top: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.device-panel {
  #energy-level {
    margin-top: 8px;
  }
}

.mobile-cointainer.device-panel {
  .camera-que-cointainer {
    font-size: 16px;
  }
  .btn {
    font-size: 18px;
    font-weight: 400;
    height: 50px;
  }
  .output {
    width: 65%;
    margin: 8px auto 64px auto;
  }
  .slide-circle {
    margin-top: 20px;
  }
}

.bp4-popover.bp4-tooltip.hardware-tip {
  width: 190px;
  border-radius: 12px;
  .bp4-popover-content {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 15px;
    background: #51575c;
    border-radius: 12px;
    padding: 10px 11px;
    text-align: left;
  }
  .bp4-popover-arrow {
    .bp4-popover-arrow-fill {
      fill: #51575c;
    }
  }
}

.radio-con {
  color: black;
}
.mobile-device-list-cointainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: 16px;
    color: #51575c;
    font-weight: 600;
  }
}

.mobile-cointainer {
  .hardware-popup-close {
    top: 22px;
  }
}

.hardware-popup-close {
  position: absolute;
  display: block;
  transform: translateY(-50%);
  right: 13px;

  button {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 19.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='100 -100 300 300' style='enable-background:new 100 -100 300 300;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2351575C;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='349.6,129.7 269.8,49.9 348.5,-30.5 328.6,-50.4 249.9,30 170.5,-49.4 150.4,-29.7 230.2,50.1 151.5,130.5 171.4,150.4 250.1,70 329.5,149.4 '/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    background-color: #6e1e1400;
  }
}

.hw-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 22;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.radio-con
  .bp4-control.bp4-radio
  input:checked
  ~ .bp4-control-indicator::before {
  background-image: radial-gradient(#83c36d, #83c36d 28%, transparent 32%);
}

.radio-con .bp4-control input:checked ~ .bp4-control-indicator {
  background-color: transparent;
  background-image: transparent;
  box-shadow: inset 0 0 0 1px #83c36d, inset 0 -1px 0 #83c36d;
}

.radio-con .bp4-control:hover input:checked ~ .bp4-control-indicator {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #83c36d, inset 0 -1px 0 #83c36d;
}

.bp4-control.bp4-radio.radio-no input:checked ~ .bp4-control-indicator::before {
  background-image: radial-gradient(#e4281b, #e4281b 28%, transparent 32%);
}

.bp4-control.radio-no input:checked ~ .bp4-control-indicator {
  background-color: transparent;
  background-image: transparent;
  box-shadow: inset 0 0 0 1px #e4281b, inset 0 -1px 0 #e4281b;
}

.bp4-control.radio-no:hover input:checked ~ .bp4-control-indicator {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #e4281b, inset 0 -1px 0 #e4281b;
}
.radio-con .bp4-control input:focus ~ .bp4-control-indicator {
  outline: none;
}

.radio-con .bp4-control .bp4-control-indicator {
  box-shadow: inset 0 0 0 1px rgb(81, 87, 92), inset 0 -1px 0 rgb(81, 87, 92);
}

.radio-con .bp4-control input:disabled:checked ~ .bp4-control-indicator {
  background: transparent;
  box-shadow: inset 0 0 0 1px #e4281b, inset 0 -1px 0 #e4281b;
}

.HWT-tip-tooltip {
  .bp4-transition-container {
    left: 18px !important;
  }
}

.device-check-cointainer .self-view video {
  border-radius: 10px;
}

.device-check-cointainer
  .self-view
  .self-view-render
  .external-controls-container {
  background: rgba(0, 0, 0, 0.5);
  width: 36px;
  height: 36px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bp4-portal {
  .bp4-alert.tap-to-play-message {
    max-width: calc(100% - 25px);
    width: 355px;
    min-height: 150px;

    .bp4-alert-body {
      display: block;
    }

    .bp4-alert-contents .message-content {
      font-weight: normal;
      color: #464646;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      padding: 10px 0;
      width: 100%;
    }

    .bp4-alert-footer {
      button.bp4-button {
        font-size: 16px;
        background-color: #666666;
        width: 120px;
        display: flex;
      }
    }
  }
}
.contact-info-cointainer {
  width: 448px;
  height: 469px;
  background: #fff;
  position: absolute;
  z-index: 21;
  padding: 48px 48px 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  border-radius: 10px;

  .contact-info-close {
    position: absolute;
    display: block;
    left: 12px;
    top: 12px;

    button {
      background-image: url(../../assets/images/hardware_check/icon_back.svg);
      background-repeat: no-repeat;
      border: none;
      background-color: #6e1e1400;
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }
  }
  .help-text-headline {
    font-size: 14px;
    color: #51575c;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .help-text {
    color: #51575c;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    max-height: 75px;
    overflow-y: auto;
    margin-bottom: 15px;
    a {
      text-decoration: none;
      color: #51575c;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .reach-out-cointainer {
    display: flex;
    flex-direction: column;
    color: #51575c;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    padding-bottom: 25px;

    .email-cointainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 14px;
      .email-image {
        background-image: url(../../assets//images/hardware_check/icon_mail.svg);
        height: 16px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding-left: 24px;
        color: #51575c;
        text-decoration: none;
      }
    }

    .phone-cointainer {
      display: flex;
      flex-direction: row;
      .phone-image {
        background-image: url(../../assets/images/hardware_check/icon_phone.svg);
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding-left: 24px;
        color: #51575c;
        text-decoration: none;
      }
    }
  }

  .contact-info-divider {
    border-bottom: 1px;
    border-bottom-color: #6d767e;
    width: 224px;
    height: 1px;
    border-bottom-style: solid;
    margin-bottom: 25px;
  }

  .tech-diffi-cointainer {
    .tech-diff-title {
      font-size: 14px;
      font-weight: 500;
      color: #51575c;
      margin-bottom: 12px;
    }
    .tech-diff-text {
      font-size: 12px;
      color: #51575c;
      font-weight: 400;
      margin-bottom: 24px;
    }
    .tech-generate-report-button {
      min-width: 224px;
      width: auto;
      height: auto;
      font-size: 14px;
      font-weight: 500;
      line-height: 35px;
      background-color: #107db7;
      color: white;
      border-radius: 10px;
      border-color: transparent;
    }
  }
}
.make-center {
  justify-content: center;
}
.contact-info-cointainer.mobile-cointainer {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  align-items: center;
  overflow-y: auto;
  padding: 48px 40px 48px;

  .contact-info-close {
    position: absolute;
    display: block;
    left: 12px;
    top: 12px;

    button {
      background-image: url(../../assets/images/hardware_check/icon_back.svg);
      background-repeat: no-repeat;
      border: none;
      background-color: #6e1e1400;
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }
  }
  .help-text-headline {
    font-size: 18px;
  }

  .help-text {
    font-size: 16px;
    margin-bottom: 24px;
    max-height: 100px;
    a {
      font-size: 16px;
    }
  }

  .reach-out-cointainer {
    font-size: 16px;
    padding-bottom: 32px;
    padding-top: 0px;

    .email-cointainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 17px;
      .email-image {
        height: 20px;
        background-size: 20px 20px;
      }
    }

    .phone-cointainer {
      .phone-image {
        height: 20px;
        background-size: 20px 20px;
      }
    }
  }

  .contact-info-divider {
    border-bottom: 1px;
    border-bottom-color: #6d767e;
    width: 279px;
    height: 1px;
    border-bottom-style: solid;
    margin-bottom: 32px;
  }

  .tech-diffi-cointainer {
    .tech-diff-title {
      font-size: 18px;
    }
    .tech-diff-text {
      font-size: 16px;
    }
    .tech-generate-report-button {
      font-size: 18px;
      height: auto;
      border: transparent;
    }
  }
}
