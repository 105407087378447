.main-logo-white {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 60px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}
